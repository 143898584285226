.light-mode {
  .bs-header {
    .bs-logo-light {
      display: none;
    }
  }
}

.dark-mode,
.dim-mode {
  .bs-header {
    .bs-logo-dark {
      display: none;
    }
  }
}

.bs-header {
  background-color: var(--white);
  color: var(--font);
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;

  .navbar {
    padding: 0;

    &-brand {
      display: flex;
    }

    &-toggler {
      border: none;
    }
  }

  .bs-logo {
    width: auto;
    height: 32px;
  }

  .navbar-collapse {
    flex-grow: 0 !important;

    .navbar-nav {
      .nav-link {
        padding: 8px 12px;
        color: var(--font);
        height: 54px;
        display: flex;
        align-items: center;
      }

      .nav-active {
        color: $primary;
      }

      .dropdown-menu {
        color: var(--font);
        box-shadow: var(--shadow);
        padding: 8px;
        border-top: 3px solid $primary !important;
        border: none;
        background-color: var(--white);
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        min-width: 250px;
        right: -50%;
        transform: translateX(0);

        li {
          a {
            padding: 6px 12px;
            font-size: 13px;
            line-height: 20px;
            color: var(--font);
            border-radius: 4px;
            display: flex;
            align-items: center;

            &:active {
              background-color: transparent;
              color: $primary;
            }

            &:hover {
              background-color: var(--bs-header-hover-bg);
              color: var(--bs-header-hover-text);
            }
          }
        }
      }
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .nav-item {
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }

    .bi {
      padding: 8px;
    }
  }
}

.bs-master-header {
  background-color: var(--white);
  color: var(--font);
  border-bottom: 1px solid var(--border);
  @media (max-width: 767px) {
    position: relative !important;
    max-width: 98%;
    .align-items-center {
      flex-direction: unset;
    }
  }
  @media (max-width: 590px) {
    display: none;
  }

  .container {
    padding: 8px !important;
    @media (max-width: 767px) {
      margin: 15px;
      .ethPrice {
        width: auto !important;
      }
    }
  }

  .form-switch {
    min-height: unset;

    span {
      display: block;
    }
  }

  button {
    height: 36px;
    width: 36px;
    padding: 0;
    border-color: var(--border);

    &:hover,
    &:active {
      background-color: var(--hover) !important;
      border-color: var(--border) !important;
    }

    i {
      font-size: 16px;
      color: $primary;
    }
  }

  .bs-search {
    &__control {
      border: 1px solid var(--border) !important;
      padding: 0 !important;
    }

    &__value-container {
      padding: 0 0 0 8px !important;
    }

    &__indicators {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-menu-mega {
    left: 0;
    right: 0;
    margin-inline: 1rem;
  }
  .navbar-expand-lg .nav-item.dropdown:hover .dropdown-menu {
    opacity: 1;
    pointer-events: initial;
    display: inherit;
    animation-name: fadeInUp;
    animation: fadeInUp .2s ease;
  }
}

.position-initial {
  position: initial !important;
}

.dropdown-menu {
  width: 100%;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 3%, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}