.bs-switch {
  padding: 4px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid var(--border);
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--white);

  span {
    padding: 4px 8px;
    border-radius: 4px;
    color: #34a1ff;
    margin: 0 2px;

    &.bs-active {
      background-color: #34a1ff;
      color: #f5f5f5;
    }
  }
}
