.bs-list {
  &-row {
    padding: 8px;
    display: flex;
    color: var(--font);
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  &-title {
    width: 290px;
    color: var(--font-secondary);
    line-height: 24px;
    font-weight: unset !important;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &-content {
    width: calc(100% - 180px);
    color: var(--font);
    line-height: 24px;
    font-weight: unset !important;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  &-full {
    width: 100%;
    color: var(--font);
    line-height: 24px;
    font-weight: unset !important;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  hr {
    margin: 4px !important;
  }

  .log-hr {
    margin: 1.5rem 0rem !important;
  }
}
