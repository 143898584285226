.mb-16 {
  margin-bottom: 4rem;
}

.career-content {
  background-color: #fafbfd;
}

.mb-12 {
  margin-bottom: 3rem;
}

.main-content {
  --bs-main-content-linear-gradient-from: #fafbfd;
  --bs-main-content-linear-gradient-to: 246, 247, 248;
  flex-grow: 1;
  min-height: 30rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-image: linear-gradient(var(--bs-main-content-linear-gradient-from) 0, rgba(var(--bs-main-content-linear-gradient-to), 0) 85rem);
}

.text-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.img-career {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
  float: right;
}

.careers {
  font-weight: 700;
  font-size: 28px;
}

.working-at-blocksscan {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.perks {
  font-weight: 600;
  font-size: 20px;
  margin: 10px;
}

.fsbase-h3 {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.perks_p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 12px;
}

.fs-base {
  font-size: 12px;
}

.fw-bold {
  font-weight: 700;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between !important;
}









