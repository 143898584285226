.bs-tab {
  margin-bottom: 16px;

  &-item {
    color: var(--font);
    background-color: var(--shadow);
    margin-right: 8px;
    padding: 4px 10px;
    border-radius: 4px;
    border: none;
    background-color: $gray;

    &.active {
      color: var(--white);
      background-color: $primary;
    }
  }

  &-content {

  }
}
