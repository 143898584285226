.bs_contract {
  .accordion-item {
    position: relative;

    &.h_active {
      border: 1px solid $primary !important;
    }

    .h_copy {
      position: absolute;
      top: 12px;
      right: 60px;
      z-index: 3;
    }

    .h_link {
      position: absolute;
      top: 11px;
      right: 35px;
      z-index: 3;

      .bi-link {
        font-size: 16px;
      }
    }
  }
}

.bs_verified {
  position: absolute;
  top: -8px;
  right: -8px;

  &-contract {
    background-color: var(--white);
    border-radius: 50%;
    color: $green;
  }

  &-text {

    font-size: 9px;
    color: var(--white);
  }
}
