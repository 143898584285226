.bs-banner {
  min-height: 268px;

  .container {
    padding: 52px 15px 72px;
  }
}

.bs-statistics {
  margin-top: -3rem !important;

  .bs-icon-avatar {
    margin: 0 16px 0 0;

    .bi {
      font-size: 24px;
      color: var(--font);
    }
  }

  .border-start {
    border-left: 1px solid var(--border);
  }
}

.bs-blocks-transaction {
  .bs-card {
    height: 100%;

    &-body {
      padding: 0 16px;
      min-height: 25.4rem;
      max-height: 25.4rem;
    }
  }

  .row_block {
    padding: 0.625rem 0;
    box-sizing: border-box;
    border-bottom: 1px solid var(--border);

    .hash-tag-dash {
      max-width: 132px;
      display: inline-block;
      vertical-align: bottom;
    }

    .validator-address {
      max-width: 100%;
    }

    .btn-icon {
      position: relative;
      line-height: 0;
      font-size: 0.8125rem;
      width: 2.93906rem;
      height: 2.93906rem;
      padding: 0;
      color: var(--font-secondary);
      background: var(--bs-bg-secondary);
      border-color: transparent;

      &_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
      }
    }
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding: 4rem;
}

.py-12 {
  padding-top: 3rem !important;
}

.pb-36 {
  padding-bottom: 9rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.mt-n36 {
  margin-top: -9rem !important;
}

@media (min-width: 768px) {
  .container-xxl {
    --bs-gutter-x: 2.5rem;
  }
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.btn-white {
  --bs-btn-bg: var(--white);
  --bs-btn-border-color: $gray;
  --bs-btn-hover-border-color: $gray;
  --bs-btn-hover-bg: $gray;
  --bs-btn-active-bg: $gray;
  --bs-btn-active-border-color: $gray;
  --bs-btn-disabled-border-color: $gray;
  --bs-btn-disabled-bg: $gray;
  --bs-btn-active-border-color: $gray;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  vertical-align: middle;
}

.group:hover .group-text-underline {
  text-decoration: underline;
  opacity: 1;
  pointer-events: all;
}

.fs-base {
  font-size: 0.9375rem;
}

.fs-sm {
  font-size: 0.78515625rem;
}

.text-cap {
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--bs-secondary-color);
  font-weight: 400;
}

.mb-1\.5 {
  margin-bottom: 0.375rem !important;
}

.snap-align-start {
  scroll-snap-align: start;
}

.gap-1\.5 {
  gap: 0.375rem !important;
}

.snap-x-mandatory {
  scroll-snap-type: x mandatory;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

.datatable-form-control {
  padding-left: 1.75rem;
  width: 14rem !important;
  margin-left: 0 !important;
}

table.dataTable thead > tr > th.sorting {
  padding-right: 30px;
}

table.dataTable thead .sorting {
  cursor: pointer;
  position: relative;
}

.tab-pane.fade {
  display: none;
}

.tab-pane.fade.active {
  display: block;
}

.accordion-button {
  color: var(--font) !important;
}

.bs-tab-item {
  color: var(--font);
  background-color: var(--bs-border-color-translucent);
  margin-top: 10px;
  position: relative;
}

.highcharts-background {
  fill: var(--white);
}

.highcharts-axis-labels {
  text {
    fill: var(--bs-text-chart) !important;
    color: var(--bs-text-chart) !important;
  }
}

.form-control,
.form-control:focus {
  color: var(--font);
}

.navbar-toggler {
  border: 1px solid var(--bs-border-color-translucent) !important;
  margin: 12px 0;
  padding: 6px;

  &:focus {
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.05);
  }
}

.bs-reset,
.bs-reset:hover {
  color: var(--white);
  background-color: var(--bs-secondary-color);
}

.theme-dropdown:after {
  display: none;
}

.theme-dropdown-menu {
  margin-top: 5px !important;
  background-color: var(--white);
  border-top: 3px solid $primary !important;

  .dropdown-item {
    color: var(--font);
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      margin-right: 8px;
    }

    .theme-block {
      display: block;
      width: 25px;
      height: 25px;
      border-radius: 50%;

      &.bg-Light {
        background-color: var(--white);
      }

      &.bg-Dark {
        background-color: var(--black);
      }

      &.bg-Dim {
        background-color: #273343;
      }
    }
  }

  .dropdown-item:hover {
    background-color: var(--hover);
  }
}

.cm-editor {
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 10px;
  padding: 5px 10px;

  .cm-gutters,
  .cm-activeLineGutter {
    display: none;
    background-color: var(--white) !important;
    border: none !important;
  }

  .cm-line > .ͼb {
    color: var(--bs-secondary-color);
  }
}

.chart-menu {
  &.active {
    background-color: var(--bs-border-color-translucent) !important;
  }
}

.navbar-toggler-icon {
  filter: invert(var(--filter-toggle));
  height: 22px;
  width: 22px;
}

.dropdown-item:focus {
  background-color: var(--hover);
}

.stats-value {
  font-size: 1.25rem;
  font-weight: 500;
}

.stats-tooltip {
  color: var(--font);
}

.gas-popover {
  width: auto;
  min-width: 250px;
}

.border-dashed {
  border-style: dashed !important;
}

.bs-header-title {
  color: var(--bs-dashboard-title);
}
.value {
  color:black;
  &:hover {
    color: $primary;
  }
}
.hoverClass {
  color:#273343;
  &:hover {
    color: $primary;
    cursor: pointer !important;
  }
}

.bs-search {
  min-width: 345px;

  &__control {
    border: none;
    padding: 5px;
    border-radius: 8px !important;
    background-color: var(--white) !important;
    box-shadow: none !important;
    border-color: transparent !important;
    min-height: 36px !important;
  }

  &__value-container {
    padding: 0 0 0 4px !important;
  }

  &__placeholder {
    display: flex;
    align-items: center;
  }

  &__menu {
    background-color: var(--bs-bg) !important;
    margin: 0 !important;
    border-radius: 8px !important;
  }

  &__menu-list {
    border-radius: 8px !important;
  }

  &__option {
    color: var(--font) !important;
    background-color: var(--white);

    &:hover {
      background-color: var(--bs-bg);
    }
  }

  &__input {
    color: var(--font) !important;
  }

  &__dropdown-indicator {
    padding: 10px;
    background-color: $primary;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;

    i {
      color: $white;

      &::before {
        vertical-align: 0;
      }
    }
  }
}
