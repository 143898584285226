.nft-block {
  padding: 16px;
  height: auto;
  min-height: 400px;
  max-width: 650px;
  display: flex;
  align-items: center;
  text-align: center;
  transition: all .2s;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;

  img, video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5rem;
  }
}
