.accordion {
  background-color: var(--white);
  background-clip: border-box;

  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--border);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 12px;
  --bs-accordion-inner-border-radius: 12px;
  --bs-accordion-btn-focus-border-color: var(--border);
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-active-color: inherit;
  --bs-accordion-active-bg: inherit;
  --bs-accordion-btn-padding-x: 12px;
  --bs-accordion-btn-padding-y: 12px;
  --bs-accordion-btn-icon-width: 14px;

  &-button {
    border-radius: var(--bs-accordion-inner-border-radius);
    font-size: 14px;

    &:not(.collapsed) {
      box-shadow: none;
    }
  }

  &-item {
    border: 1px solid var(--border) !important;
    border-radius: var(--bs-accordion-inner-border-radius);
  }

  &-header {
    border-radius: var(--bs-accordion-inner-border-radius);
  }

  &-body {
    border-radius: var(--bs-accordion-inner-border-radius);
    background-color: var(--white);
    color: var(--font);
  }
}
