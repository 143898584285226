.bs-btn {
  color: var(--font);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-hover-bg);

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &:disabled {
    color: var(--font);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &:active,
  &.active, &:focus,
  &.show,
  &:first-child:active {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
    outline: none;
  }
}

.custom-btn {
  color: #081D35 !important;
  background-color: $gray !important;
  border-color: $gray;

  :hover {
    background-color: #dee2e6;
    border-color: #e9ecef;
  }

  :disabled {
    color: #6c757d;
    background-color: #e9ecef;
    border-color: #e9ecef;
  }

  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    color: #000 !important;
    background-color: #bdc4ca !important;
    border-color: #bdc4ca;
  }
}

.bs-btn-cust {
  color: $white;
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-hover-bg);

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &:disabled {
    color: var(--font);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &:active,
  &.active, &:focus,
  &.show,
  &:first-child:active {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
    outline: none;
  }
}

.custom-drop {
  min-width: 120px;
  text-align: left;
  border: 1px solid var(--bs-btn-hover-bg);
  color: var(--font);
  background-color: var(--bs-btn-bg);

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
  }
}

.custom-dropdown-menu {
  min-width: 120px;
  text-align: left;
  color: #333;
  border: 1px solid #ccc;

  .dropdown-item {
    &:hover {
      background-color: #ddd;
    }
  }
}

.bs-sign-btn {
  color: var(--font);
  padding: 4px 6px;
  margin-top: 13px;

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: $theme;
    color: $theme;
  }

  &:active,
  &.active, &:focus,
  &.show,
  &:first-child:active {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
    outline: none;
  }
}

.bs-btn-network {
  color: var(--font);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-hover-bg);

  &:hover {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &:disabled {
    color: var(--font);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-hover-bg);
  }

  &:active,
  &.active, &:focus,
  &.show,
  &:first-child:active {
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-bg);
    outline: none;
  }
}