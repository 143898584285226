.bs-tooltip {
  &-content {
    width: unset !important;
    background-color: var(--black) !important;
  }

  &-text {
    display: block;
    max-width: 200px;
    color: var(--white);
    background-color: var(--black);
    word-wrap: break-word;
    padding: 4px;
    margin: 0 !important;
    border-radius: $border-radius;
    font-size: 12px;
    opacity: 1;
  }

  &-arrow {
    color: var(--black) !important;
  }
}


.bs-tooltip-light {
  &-content {
    width: unset !important;

  }

  &-text {
    display: block;
    max-width: 400px;
    word-wrap: break-word;
    padding: 8px;
  }
}

.popup-content {
  z-index: 9999999999 !important;
}
