.td-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .td-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .td-title {
    font-size: 2rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 0.5rem;
  }
  
  .td-subtitle {
    color: #6b7280;
    font-size: 1rem;
  }
  
  .td-main-section {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
  }
  
  .td-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .td-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .td-label {
    font-weight: 500;
    color: #374151;
    font-size: 0.875rem;
  }
  
  .td-input {
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: border-color 0.15s ease;
    width: 100%;
  
    &:focus {
      outline: none;
      border-color: #6366f1;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1);
    }
  
    &::placeholder {
      color: #9ca3af;
    }
  }
  
  .td-input-error {
    border-color: #ef4444;
    
    &:focus {
      border-color: #ef4444;
      box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.1);
    }
  }
  
  .td-help-text {
    color: #6b7280;
    font-size: 0.75rem;
  }
  
  .td-error-message {
    color: #ef4444;
    font-size: 0.75rem;
  }
  
  .td-connect-button,
  .td-deploy-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #32a2ff;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.15s ease;
  
    &:hover {
      background-color: #0673cd;
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
    }
  }
  
  .td-info-section {
    background-color: #f9fafb;
    border-radius: 0.5rem;
    padding: 1.5rem;
  }
  
  .td-section-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 0.75rem;
  }
  
  .td-section-text {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .ci-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .ci-connect-section {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .ci-section-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 1rem;
  }
  
  .ci-subtitle {
    font-size: 1.25rem;
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .ci-input-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .ci-input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: border-color 0.15s ease;
  
    &:focus {
      outline: none;
      border-color: #32a2ff;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1);
    }
  }
  
  .ci-label {
    display: block;
    font-weight: 500;
    color: #374151;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .ci-button {
    padding: 0.75rem 1.5rem;
    background-color: #32a2ff;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.15s ease;
  
    &:hover {
      background-color: #4f46e5;
    }
  }
  
  .ci-interaction-section {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
  }
  
  .ci-functions-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .ci-function-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .ci-function-button {
    padding: 0.75rem;
    text-align: left;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.15s ease;
  
    &:hover {
      background-color: #f3f4f6;
    }
  
    &.ci-selected {
      background-color: #32a2ff;
      color: white;
      border-color: #32a2ff;
    }
  }
  
  .ci-execution-panel {
    background-color: #f9fafb;
    border-radius: 0.375rem;
    padding: 1.5rem;
  }
  
  .ci-execute-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #10b981;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;
  
    &:hover {
      background-color: #059669;
    }
  }
  
  .ci-result {
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.375rem;
    border: 1px solid #e5e7eb;
  }
  
  .ci-result-title {
    font-weight: 500;
    color: #374151;
    margin-bottom: 0.5rem;
  }
  
  .ci-result-content {
    font-family: monospace;
    white-space: pre-wrap;
    word-break: break-all;
    background-color: #f9fafb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
  
  @media (max-width: 768px) {
    .ci-functions-container {
      grid-template-columns: 1fr;
    }
  }
  