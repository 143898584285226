@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800&display=swap");

$black: #000000;
$white: #ffffff;
$gray: #e9ecef;
$light-gray: #f7f7f8;
$theme: var(--color-theme);
$primary: var(--color-primary);
$green: #00a186;
$light-green: #eaf5f3;
$red: #dc3545;
$light-red: #fdebed;
$yellow: #ffb61a;
$orange: #dd6b20;
$light-yellow: #fcf6eb;

$font: #212529; //#2c2f32;
$font-for-dark: #ccc;
$font-secondary: #6c757d;
$font-secondary-for-dark: #6c757d;

$bs-secondary-color: rgba(33, 37, 41, 0.75);
$bs-secondary-color-for-dark: #bbb;

$bg: #f6f8fa;
$bg-for-dark: rgb(17, 17, 17);
$bg-for-dim: #111a2e;

$bg-secondary: rgb(248, 249, 250);
$bg-secondary-for-dark: rgb(21, 21, 21);
$bg-secondary-for-dim: rgb(22, 33, 56);

$border: rgb(233, 236, 239);
$border-for-dark: rgb(34, 34, 34);
$border-for-dim: #273343;

$shadow: rgba(189, 197, 209, 0.2) 0 8px 20px 0;
$shadow-for-dark: rgba(255, 255, 255, 0.05) 0 8px 20px 0;
$shadow-for-dim: 0 0.5rem 1.2rem rgba(82, 85, 92, 0.15);

$hover: rgba(0, 0, 0, 0.025);
$hover-for-dark: rgba(255, 255, 255, 0.025);

$bs-gray: #fafbfd;
$bs-gray-for-dark: #181818;
$bs-gray-for-dim: #162138;
$bs-light-gray-for-dark: #222222;
$bs-light-gray-for-dim: #273343;

$bs-btn-bg: #fff;
$bs-btn-bg-for-dim: #162138;
$bs-btn-bg-for-dark: #151515;

$bs-btn-hover-bg: #e9ecef;
$bs-btn-hover-bg-for-dim: #273343;
$bs-btn-hover-bg-for-dark: #222;

$bs-header-hover-bg: #e9ecef;
$bs-header-hover-bg-for-dim: #3a4759;
$bs-header-hover-bg-for-dark: #252525;

$bs-header-hover-text: #212529;
$bs-header-hover-text-for-dim: #e3e9f1;
$bs-header-hover-text-for-dark: #f5f5f5;

$bs-dashboard-text: #212529;
$bs-dashboard-text-for-dim: #e3e9f1;
$bs-dashboard-text-for-dark: #f5f5f5;

$bs-dashboard-title: #6c757d;
$bs-dashboard-title-for-dim: #93a3b8;
$bs-dashboard-title-for-dark: #bbb;

$bs-bg-card: rgb(8, 29, 53);
$bs-bg-card-for-dim: rgb(11, 17, 32);
$bs-bg-card-for-dark: rgb(10, 10, 10);

$bs-text-chart: rgb(102, 102, 102);
$bs-text-chart-for-dim: rgb(170, 170, 170);
$bs-text-chart-for-dark: rgb(136, 136, 136);

$bs-text-card-title: rgb(255, 255, 255);
$bs-text-card-title-for-dim: rgb(242, 246, 250);
$bs-text-card-title-for-dark: rgb(250, 250, 250);