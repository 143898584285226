.grid--support {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .grid__item {
    flex-grow: 1;

    &.grid__item--padding {
      width: calc((100% - 30px * 2 - 50px * 6) / 3);
      padding: 50px;
      margin-bottom: 0px;
      margin-right: 30px;
      background-color: var(--white);
      float: left;
      -webkit-transition: all .3s ease-out;
      -moz-transition: all .3s ease-out;
      transition: all .3s ease-out;

      &:hover {
        background-color: #00ccff;
        color: var(--white);
        box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
        -moz-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
        -webkit-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
        transform: translate(0, -10px);
        -webkit-transform: translate(0, -10px);
        -moz-transform: translate(0, -10px);

        .grid__icon {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .grid__title {
    font-size: 24px;
    font-weight: 700;
    padding: 20px 0 20px 0;
    margin: 0px 0 20px 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0;
      width: 40px;
      height: 1px;
      background: #38cbdd;
      opacity: .5;
      -webkit-transition: all .5s ease-out;
      -moz-transition: all .5s ease-out;
      transition: all .5s ease-out;
    }
  }

  .grid__more {
    height: 32px;
    line-height: 30px;
    color: var(--white);
    background-color: #00ccff;
    padding: 0 15px;
    margin: 0px 0 0 0;
    border: 1px #00ccff solid;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background-color 0.3s ease;
    -moz-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;

    &:hover {
      color: var(--white);
      background-color: transparent;
      border: 1px var(--white) solid;
    }
  }
}

.grid--support .grid__item:hover .grid__title:after {
  width: 100%;
  background: var(--white);
}

.grid--support .grid__item:hover .grid__text {
  color: var(--white);
}

.grid--support .grid__item:hover .grid__more {
  color: var(--white);
  background-color: transparent;
  border: 1px var(--white) solid;
}

.grid--support .grid__item.grid__item--padding {
  flex: 1 1 calc(33.333% - 40px);
  padding: 20px;
  margin: 10px;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@media (max-width: 768px) {
  .grid--support .grid__item.grid__item--padding {
    flex-basis: calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .grid--support .grid__item.grid__item--padding {
    flex-basis: calc(100% - 40px);
  }
}
