.bs-modal {
  &-content {
    width: unset !important;
    padding: 0 !important;
    background: transparent !important;
    border: 0 !important;

    .bs-card {
      background-color: var(--white);
    }
  }
}

.popup-content {
  background-color: var(--white) !important;
}

.bs-popup {
  &-content {
    width: 300px !important;
  }
}
