.privacy-card {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: hwb(0 100% 0%);
  border-radius: 30px;
  font-family: sans-serif;

  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: $primary;
    position: relative;
  }

  .title-bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 1920 100.1' width='100%' height='32'%3E%3Cpath class='fill-blue' fill='%23ffffff' d='M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z'%3E%3C/path%3E%3C/svg%3E");
    background-size: cover;
  }

  .title-bar .logo {
    margin-right: 20px;
    width: 100px;
  }

  .title-bar .title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: var(--white);
  }

  .logo {
    width: 50px;
    margin-right: 10px;
  }

  .privacy-policy-content {
    line-height: 1.6;
    font-size: 16px;
    color: #555;
    padding: 20px;
  }

  .privacy-policy-content p {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .privacy-policy-content h2 {
    color: #333;
    font-weight: 600;
    font-size: 14px;
  }
}
