.bs-advance-filter-title {
  display: flex;
  justify-content: space-between;
}

.bs-custom-form-switch {
  padding-left: 0px !important;
}

.bs-advance-filter-display-chart {
  display: flex;
  justify-content: space-between;
}

.custom-checkbox-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3px 5px;
}

.custom-checkbox-align label {
  margin-bottom: 0;
  flex-grow: 1;
}

.custom-checkbox-align .form-check-input {
  margin-left: 10px !important;
}

.bs-offcanvas-title {
  border-bottom: 1px solid var(--border);
  color: var(--font);
}

.bs-offcanvas-title-font {
  font-size: 1.171875rem;
  font-weight: 500;
}

.search-field {
  max-width: 200px;
}

.filter-btn {
  background: none;
  border: none;
  color: var(--font);
}

.progress-ring {
  transform: rotate(-90deg);
}

.progress-ring__background {
  stroke: #e0e0e0;
}

.progress-ring__progress {
  stroke: rgb(44, 175, 254);
  stroke-linecap: round;
  transition: stroke-dashoffset 0.35s;
  transform-origin: 50% 50%;
}
