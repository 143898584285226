

.main-container {
  padding: 20px;
  background-color: #fafbfb;
  max-width: 1320px;
  margin: 0 auto;
  background-image: linear-gradient(var(--bs-main-content-linear-gradient-from) 0, rgba(var(--bs-main-content-linear-gradient-to), 0) 85rem);
}

.proritycontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 356px;
  margin-bottom: 3rem;
}

.proritycontent {
  flex: 1;
  padding-right: 20px;
}

.prorityimage {
  flex: 1;
}

.prority-h1 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 8px;
}

.priority-p {
  margin-bottom: 10px;
}

.priority-btn {
  height: 32px;
  width: 90px;
  padding: 2px;
}

.Howto {
  font-size: 18px;
  font-weight: 600;
}

.form-padding {
  padding: 20px;
}

[data-img-theme="dark"] {
  filter: brightness(50%);
}

@media (max-width: 817px) {
  .proritycontainer {
    flex-direction: column-reverse;
  }
  .prorityimage {
    margin-top: 20px;
  }
}
