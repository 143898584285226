.custom-fs {
  font-size: .9375rem;
}

.offset-scroll {
  scroll-margin-top: 85px;
}

.span-pd {
  padding-bottom: 2.4rem !important;
}

.margin-b {
  margin-bottom: 1.5rem !important;
}

.custom-bg-dark {
  background-color: var(--bs-bg-theme);
}

.gap-8 {
  gap: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.small, small {
  font-size: .875em;
}

.cutom-hov {
  &:hover {
    background-color: var(--bs-btn-hover-bg);
  }
}

.transition-all {
  transition: all .2s ease !important;
}

.bs-font-prm {
  color: $primary !important;
}