.bs-pagination {
  display: flex;
  justify-content: flex-end;

  .btn {
    padding: 2px 8px;
    line-height: 12px;
    height: 30px;
    min-width: 30px;
  }

  select {
    width: 64px;
    font-size: 12px;
    padding: 2px 10px;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }

  .pagination {
    li {
      &.page-item {
        a {
          font-size: 12px;
          padding: 4px 10px;
          color: $primary !important;

          &:focus {
            box-shadow: none;
          }
        }
      }

      &.active a,
      &.active a:focus,
      &.active a:hover {
        border: 1px solid var(--border) !important;
        background-color: $primary !important;
        color: var(--font) !important;
      }
    }
  }
}

.page-link.disabled,
.disabled > .page-link {
  background-color: transparent;
}

.nw-pagination {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  @media (max-width: 767px) {
    justify-content: flex-start;
  }

  .btn {
    padding: 2px 8px;
    line-height: 16px;
  }

  select {
    width: 64px;
    font-size: 12px;
    padding: 2px 10px;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: none !important;
      box-shadow: none !important;
    }

  }

  .pagination {
    height: 30px !important;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: transparent;

      &.page-item {
        .page-input {
          display: flex;
          align-items: center;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          padding: 2px 8px;
          color: $primary !important;
          text-decoration: none;
          border-radius: 4px;
          background-color: transparent;
        }
        .page-link {
          display: flex;
          align-items: center;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          padding: 2px 8px;
          color: $primary !important;
          text-decoration: none;
          border-radius: 4px;
          background-color: transparent;

          &:focus,
          &:focus-visible {
            outline: none !important;
            box-shadow: none !important;
          }

          &:hover {
            background-color: $primary !important;
            color: var(--font) !important;
          }
        }

        &.disabled {
          .page-link {
            background-color: var(--bs-body-bg) !important;
            cursor: not-allowed;
            opacity: 0.6;
            color: var(--disabled-font-color) !important;
          }
        }

        &.active {
          .page-link {
            border: 1px solid var(--border) !important;
            background-color: $primary !important;
            color: var(--font) !important;
          }
        }
      }
    }

    .page-item.disabled .page-link {
      color: var(--bs-secondary-color) !important;
    }

    .page-item.active .page-link {
      color: var(--bs-secondary-color) !important;
    }
  }

  span.page-link {
    background-color: var(--bs-body-bg) !important;
    color: var(--font) !important;
    padding: 2px 8px;
    line-height: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
  }
}
